import moment from 'moment'
import Vue from 'vue'

Vue.filter('formatDate', value => moment(String(value)).format('DD/MM/YYYY h:mm A'))
Vue.filter('formatDay', value => moment(String(value)).format('DD/MM/YYYY'))
Vue.filter('formatMonth', value => moment(String(value)).format('MM/YYYY'))
Vue.filter('formatMeterMode', value => (value === 1 ? 'Automatic' : 'Manual'))
Vue.filter('formatMeterValveStatus', value => (value === 1 ? 'Open' : 'Close'))
Vue.filter('formatCurrency', value => Number(parseInt(value, 10).toFixed(1)).toLocaleString())
Vue.filter('formatCurrencyWithDecimal', value => Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
Vue.filter('formatAccountBalance', value => {
  if (value > 0) {
    return Number(parseInt(value, 10).toFixed(1)).toLocaleString()
  }

  return 0.00
})
Vue.filter('formatTotalUserDebt', (value, unaccountedDebt) => {
  let debt = value
  if (debt < 0) {
    debt = Number(parseInt(-value, 10).toFixed(1))
  } else {
    debt = 0
  }

  const totalDebt = Math.floor(debt) + Math.floor(unaccountedDebt)

  return Number(parseInt(totalDebt, 10).toFixed(1)).toLocaleString()
})
Vue.filter('formatTotalUserCredit', value => {
  if (value > 0) {
    return Number(parseInt(value, 10).toFixed(1)).toLocaleString()
  }

  return 0.0
})
Vue.filter('formatMeterReadingStatus', value => {
  let statusString
  switch (value) {
    case 0:
      statusString = 'Not Paid'
      break
    case 1:
      statusString = 'Paid'
      break
    case 2:
      statusString = 'Balance'
      break
    case 3:
      statusString = 'Credit'
      break

    default:
      statusString = 'Unknown'
      break
  }

  return statusString
})
